import { createObservableDataAction, IAction } from '@msdyn365-commerce/core';
import { getGenericAction } from '@msdyn365-commerce-modules/retail-actions';

const createOverrideInput = () => {
    throw new Error('Unneeded data action');
};

export const getOverrideDataAction = createObservableDataAction({
    id: 'get-empty-override',    // This can be anything EXCEPT the data action path ('actions/get-empty-override')
    action: <IAction<any>>getGenericAction,
    input: createOverrideInput
});

export default getOverrideDataAction;
